@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

a {
  color: #9f025e;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.nunito-sans {
  font-family: "Nunito Sans", sans-serif;
}

.caret-none {
  caret-color: transparent;
}

.btn {
  @apply text-center w-full max-w-xs text-sm mx-auto rounded-3xl text-white font-medium px-3 py-2;
}

.btn:focus {
  @apply outline-none;
}

.btn-primary {
  background: linear-gradient(to right, #f9c929, #9f025e);
}

.bg-darkBlue {
  background: #002848;
  box-shadow: inset 0 0 50px 20px rgba(0, 0, 0, 0.25);
}

.bg-lightBlue {
  background: linear-gradient(60deg, #227697, #166ead, #2a8bb2, #2da5cb);
  box-shadow: inset 0 0 30px 10px rgba(0, 0, 0, 0.25);
}

.grayscale {
  filter: grayscale(100%);
}

h1 {
  line-height: 1em;
  font-family: "Teko", sans-serif;
}

h2 {
  @apply text-4xl;
  line-height: 1em;
  font-family: "Teko", sans-serif;
}

h4 {
  @apply text-sm font-bold uppercase;
  letter-spacing: 0.08rem;
}

.container {
  @apply max-w-7xl mx-auto  px-4;
}

@screen sm {
  .container {
    @apply px-6;
  }
}

@screen lg {
  .container {
    @apply px-8;
  }
}

.hero-game-cta {
  @apply bg-cover relative overflow-hidden bg-bottom;
  height: 400px;
}

.hero-game-cta .caption {
  @apply absolute left-0 right-0 bottom-0 p-4 uppercase font-bold text-lg;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
}

.hiw-item {
  @apply overflow-hidden mb-6;
}

.hiw-item .img-holder {
  @apply bg-cover;
  height: 200px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.4);
}

.hiw-item .caption {
  @apply p-6;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.perk-item {
  @apply overflow-hidden bg-cover relative mb-6;
  height: 350px;
}

.perk-item .caption {
  @apply p-6 absolute left-0 right-0 bottom-0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
}

.font-teko {
  font-family: "Teko", sans-serif;
}

.feature-icon {
  @apply mr-4;
  width: 60px;
  height: 60px;
}

.input {
  @apply block px-0 py-2 mb-6;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #3ad5e5;
  width: 100%;
}

.nav-link {
  @apply uppercase text-sm font-bold ml-6;
  letter-spacing: 0.06rem;
}

.flashMessage {
  @apply fixed rounded-md;
  top: 20px;
  right: 20px;
  width: 300px;
  padding: 15px;
  background: #d5f7fd;
  z-index: 999;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.4);
}

@media (max-width: 568px) {
  .flashMessage {
    right: 10px;
    left: 10px;
    width: auto;
  }
}

.closeBtn {
  @apply absolute p-1;
  right: 4px;
  top: -5px;
  z-index: 99;
  background-color: #9a2634;
  border-radius: 15px;
}
